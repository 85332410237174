import { jsx } from "react/jsx-runtime";
import { lazy, useMemo } from "react";
const LazyPolicy = lazy(
  () => import("./policy-C3T3d8y0.js").then((module) => ({
    default: module.Policy
  }))
);
function Policy({
  id: _id,
  name: _name,
  ...props
}) {
  return /* @__PURE__ */ jsx(LazyPolicy, { ...props });
}
const usePolicyContent = () => {
  return useMemo(
    () => ({
      cookiePolicy: {
        component: Policy
      },
      privacyPolicy: {
        component: Policy
      }
    }),
    []
  );
};
export {
  LazyPolicy as Policy,
  usePolicyContent
};
